<template>
    <v-dialog v-model="viewOps.dialog" max-width="550px">
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>View User</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text>
                <v-row dense>
                    <v-col>
                        <v-card flat>
                            <v-tabs hide-slider>
                                <v-tab v-for="tab in tabs" :key="tab">
                                    <span class="avsb-tab-title">{{
                                        tab
                                    }}</span>
                                </v-tab>

                                <!-- Profile Tab -->
                                <v-tab-item transition="false">
                                    <TabUserProfile />
                                </v-tab-item>

                                <!-- Settings Tab -->
                                <v-tab-item transition="false">
                                    <TabUserSettings />
                                </v-tab-item>

                                <!-- Credentials Tab -->
                                <v-tab-item transition="false">
                                    <TabUserSecurity />
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
    components: {
        TabUserSecurity: () =>
            import("@/components/admin/tabs/tabUserSecurity"),
        TabUserProfile: () => import("@/components/admin/tabs/tabUserProfile"),
        TabUserSettings: () =>
            import("@/components/admin/tabs/tabUserSettings"),
    },
    computed: {
        ...mapState("usersAdminMgmt", ["viewOps"]),
    },
    data: () => ({
        tabs: ["profile", "settings", "security"],
    }),
    methods: {
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
        },
    },
    name: "DialogUserView",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
